import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, of } from 'rxjs';

// Definition für maximal 3 Ebenen tief verschachtelte Übersetzungen
type LeafLevel = Record<string, string>;

type BranchLevel = Record<string, string | LeafLevel>;

type TranslationObject = Record<string, string | BranchLevel>;

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private http = inject(HttpClient);
  private translations: TranslationObject = {};

  async loadTranslations(lang: string): Promise<void> {
    try {
      this.translations = await firstValueFrom(
        this.http
          .get<TranslationObject>(`/assets/i18n/${lang}.json`)
          .pipe(catchError(() => of({}))),
      );
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error loading translations:', error.message);
      }
    }
  }

  translate(key: string, params?: Record<string, string>): string {
    const translation = this.getNestedTranslation(this.translations, key);
    if (typeof translation !== 'string') return key;

    if (params) {
      return Object.entries(params).reduce(
        (text, [param, value]) => text.replace(`{{${param}}}`, value),
        translation,
      );
    }

    return translation;
  }

  private getNestedTranslation(obj: TranslationObject, path: string): string | undefined {
    const parts = path.split('.');
    let current: string | BranchLevel | undefined = obj[parts[0]];

    for (let i = 1; i < parts.length; i++) {
      if (typeof current === 'string' || current === undefined) {
        return undefined;
      }
      current = current[parts[i]];
    }

    return typeof current === 'string' ? current : undefined;
  }
}
